<template>

  <div class="question section questionnaire-content">
    <div class="container">
      <article class="box">
        <h1>{{ content.title }}</h1>
        <div v-html="content.html"></div>
      </article>
    </div>
  </div>
</template>


<script>

export default {
  name: 'QuestionnaireContent',
  props: {
    'content': Object
  },
  data: function () {
    return {}
  }
}

</script>


<style>

</style>
