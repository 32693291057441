export const NolostResearch = {
  data () {
    return {

    }
  },
  methods: {
    getColumnSize(numberOfOptions, options = undefined) {
      if (options !== undefined && Object.prototype.hasOwnProperty.call(options,'size')) {
        return options.size
      }

      switch (numberOfOptions) {
        case 1:
          return 'is-full'
        case 2:
          return 'is-half'
        case 3:
          return 'is-4'
        case 4:
          return 'is-3'
        case 5:
          return 'is-full'
        case 6:
          return 'is-full'
        case 7:
          return 'is-full'
        case 8:
          return 'is-full'
        case 9:
          return 'is-full'
        case 10:
          return 'is-full'
        default:
          return 'is-full'
      }
    }
  }
}
