<template>
    <div v-if="questionnaire && !finished">
        <template v-if="status == 'closed'">
            <section class="hero is-primary is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="title">{{ $t('errors.project_closed.title') | capitalize }}</h1>
                    </div>
                </div>
            </section>
        </template>
        <template v-else>
            <div class="content" id="questionnaire-page">
                <!-- <div v-if="progress" class="container">
                    <progress class="progress is-primary" :value="progress" max="100"> {{ progress }} %</progress>
                </div> -->
                <div v-for="(page, index) in questionnaire.pages" :key="index">
                    <questionnaire-page
                            :answers="answers"
                            :page="page"
                            :url="url"
                            :participant="participant"
                            :key="index"
                            ref="pagesRef"
                            v-if="currentPage == index && canViewPage(page)">
                    </questionnaire-page>
                </div>
                <section class="progress-footer">
                    <div class="container">
                        <div class="columns is-mobile is-1">
                            <div class="column">
                                <button
                                    v-if="currentPage > 0"
                                    @click="prevPage()"
                                    class="button is-light">
                                    &laquo; {{ $t('interface.previous') | capitalize }}
                                </button>
                            </div>
                            <div v-if="progress"
                                class="column has-text-centered has-text-primary is-size-6 is-half-tablet is-three-quarters-desktop">

                                <progress class="progress is-primary" :value="progress" max="100">{{ progress }} %</progress>
                                {{ progress }} %
                            </div>
                            <div class="column">
                                <button
                                    v-if="currentPage < questionnaire.pages.length - 1"
                                    class="button is-link is-pulled-right is-primary"
                                    @click="nextPage()">
                                    {{ $t('interface.next') | capitalize }} &raquo;
                                </button>
                                <button
                                    v-if="currentPage === questionnaire.pages.length - 1"
                                    class="button is-link is-pulled-right is-primary"
                                    @click="completeQuestionnaire()">
                                    {{ $t('interface.finish') | capitalize }}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </template>
    </div>
    <div v-else-if="questionnaire && finished">
        <div class="content" id="page">
            <section class="hero is-primary is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="title">{{ $t('communicatie.dank') }}</h1>
                        <p>{{ $t('communicatie.voltooid', {'questionnaire_name' : questionnaire.name}) }}</p>
                        <p v-if="project.closing_text" v-html="project.closing_text"></p>
                        <p>{{ $t('communicatie.geluk') }}</p>
                        <h1 class="title has-text-white is-5">Nolost</h1>
                        <h2 class="subtitle has-text-white is-6 is-italic">Feeling Good, Working Better</h2>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <div v-else>
        <div class="content" id="page">
            <section class="hero is-primary is-fullheight">
                <div class="hero-body">
                    <div class="container">
                        <h1 class="title has-text-white">{{ $t('interface.laden')}}...</h1>
                        <p class="loader is-size-1"></p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>


<script>
    import QuestionnairePage from './QuestionnairePage.vue'
    import { FILTER_OPERATORS } from '../../constants/filters.js'
    import axios from 'axios'
    import _ from 'lodash'

    export default {
        name: 'Questionnaire',
        components: {
            QuestionnairePage
        },
        data: function () {
            return {
                questionnaire: this.getQuestionnaire(),
                currentPage: 0,
                finished: false,
                progress: '0',
                project: {},
                participant: {},
                answers: [],
                url: {}
            }
        },
        watch: {
            '$i18n.locale': function () {
                this.getQuestionnaire()
            },
            currentPage : function () {
                localStorage.currentPage = this.currentPage
            },
        },
        methods: {
            getQuestionnaire() {
                const url = process.env.VUE_APP_API_URL + '/api/questionnaire/' + this.$route.params.questionnaire + '/'
                // Set header, for first login because else the browser language is requested, not the url parameter
                axios.defaults.headers.common['Accept-Language'] = this.$i18n.locale
                axios.get(url).then((response) => {
                    const r = response.data
                    this.answers = r.answers
                    this.questionnaire = r.questionnaire
                    this.project = r.project
                    this.participant = r.participant
                    this.finished = r.finished
                    this.status = r.status
                    this.url['metadata'] = r.metadata
                    this.setAnswers() // Set the answers
                    if (this.finished) {
                        localStorage.removeItem('questionnaire')
                        localStorage.removeItem('currentPage')
                        localStorage.removeItem('slug')
                    }
                    // console.log(this.answers)
                    this.$store.commit('initiateAnswers', this.answers)

                    // For each page in the questionnaire, check if each page is viewable
                    this.questionnaire.pages = this.questionnaire.pages.map(page => {
                        page.canView = this.canViewPage(page)
                        return page
                    })

                    this.getProgress()

                }).catch(function (error) {
                    console.error(error)
                    // TODO: show a nice error when a participant cannot load a questionnaire?
                })
            },
            setAnswers() {
                // This check if a user has already answered a question and set the value accordingly
                // This is done when a participant comes back to the page at a later stage...
                const answers = this.answers
                this.questionnaire.pages.map(function (page) {
                    if (page.items) {
                        page.items.map(function (item) {
                            if (item && item.type === 'question') {
                                const answer = answers.filter((a) => a.question_id === item.id)[0]
                                // console.log(answer)
                                if (answer && answer['value'] && answer['value'].startsWith("[")) {
                                    let arrayAnswer = JSON.parse(answer['value'].replace(/'/g, '"'))
                                    item['answer'] = arrayAnswer
                                    item['answer_other_value'] = answer['other_value']
                                }
                                else if (answer && answer['value']) {
                                    item['answer'] = answer['value']
                                    item['answer_other_value'] = answer['other_value']
                                }
                            }
                        })
                    }
                })
            },
            checkPageAnswers: function () {
                let questionErrors = []
                let firstError = true

                // Does page contain questions?
                if (this.$refs && this.$refs.pagesRef && this.$refs.pagesRef[0].$refs.questionsRef) {

                    // Loop through questions
                    for (let index = 0; index < this.$refs.pagesRef[0].$refs.questionsRef.length; index++) {

                        let question = this.$refs.pagesRef[0].$refs.questionsRef[index]
                        // Is a required question empty?
                        if (question.isRequired && !question.isCompleted) {

                            question.setError(this.$i18n.t('errors.incomplete_question.title') + ": " + this.$i18n.t('errors.incomplete_question.message'))
                            questionErrors.push(question)

                            // Scroll to first error
                            if (firstError) {
                                document.getElementById(question.questionObject.id).scrollIntoView();
                                firstError = false
                            }
                        }
                        // Is there a validation error?
                        else if (question.status == "question-error") {
                            questionErrors.push(question)
                        }   
                    }
                }

                return questionErrors.length === 0
            },
            checkQuestionnaireAnswers: function () {
                let errors = []
                let self = this
                
                // For each page
                this.questionnaire.pages.map(function (page, pageCount) {
                    
                    // We need to check all items per page, but only the pages we can view
                    if (page.canView) {

                        // Check each item on the page
                        page.items.map(function (item) {
                            // But only question types, not content
                            if (item.type == 'question') {
                                // And only check the questions a user can view and those that are required
                                if (typeof item.answer == 'undefined' && item.required && self.canViewQuestion(item)) {
                                    errors.push({"page": pageCount, "id": item.id})
                                }
                            }
                        })
                    }
                })

                // If there are no errors, we can complete the questionnaire
                if (errors.length === 0) {
                    return true
                } else if (errors.length > 0) { // Navigate back to the first error
                    this.gotoQuestion(errors[0].page, errors[0].id)
                }
            },
            nextPage: function () {
                // Check if all the answers are set on this page
                if (this.checkPageAnswers(this.currentPage)) {

                    this.currentPage++ // Increment the page

                    // Check if the page conditions are statisfied, otherwise we call next page again
                    if (!this.canViewPage(this.questionnaire.pages[this.currentPage])) {
                        this.nextPage()
                    }
                

                    this.updatePageQuery() // Update query param in url
                    this.getProgress() // Update progress
                    window.scrollTo(0, 0) // Scroll to the top
                } else {
                    // Show an error with an error message
                    this.$notify({
                        group: 'notifications',
                        title: this.$i18n.t('errors.incomplete.title'),
                        text: this.$i18n.t('errors.incomplete.message'),
                        type: 'error'
                    })
                }
            },
            prevPage: function () {
                this.currentPage--

                // Check if the page conditions are statisfied, otherwise we call prev page again
                if (!this.canViewPage(this.questionnaire.pages[this.currentPage])) {
                    this.prevPage()
                }
                

                this.updatePageQuery()
                this.getProgress()
                window.scrollTo(0, 0)
            },
            gotoPage: function (page) {
                this.currentPage = page
                this.updatePageQuery()
                this.getProgress()
                window.scrollTo(0, 0)
            },
            gotoQuestion: function(page, id) {
                // Go to the correct page
                this.gotoPage(page)
                // Delay the scroll so we can find the question/error id
                _.delay(function () {
                    document.getElementById(id).scrollIntoView()
                }, 200)
            },
            getProgress: function () {
                // For each page in the questionnaire, check if each page is viewable
                // This is necessary to calculate the correct progress
                this.questionnaire.pages = this.questionnaire.pages.map(page => {
                    page.canView = this.canViewPage(page)
                    return page
                })
                
                // Get the pages count based on how many we can view
                var pagesCount = this.questionnaire.pages.filter(page => page.canView).length - 1

                // Get the current page index if the object page object matches
                var currentPageIndex = this.questionnaire.pages.filter(page => page.canView).findIndex(page => page === this.questionnaire.pages[this.currentPage])

                // Show the progress if there is more then one page
                if (pagesCount >= 1) {
                    this.progress = ((currentPageIndex / pagesCount) * 100).toFixed(0)
                } else {
                    this.progress = false
                }
            },
            // To save state between refreshes
            updatePageQuery: function () {
                let from  = this.$route.fullPath
                let to = this.$router.resolve({
                    name: 'questionnaire',
                    params: {
                        questionnaire: this.$route.params.questionnaire
                    },
                    query: {
                        page: this.currentPage + 1
                    }
                }).route.fullPath

                // Don't navigate if the from/to are the same (redundant navigation)
                if(from === to) { 
                    return 
                }
                
                // Otherwise, we do navigate
                this.$router.replace(to).catch(err => {
                    console.error(err)
                })
            },
            // Function to complete a questionnaire
            completeQuestionnaire: function () {
                // If all the questions are answered:
                if (this.checkQuestionnaireAnswers()) {
                    const completeUrl = process.env.VUE_APP_API_URL + '/api/questionnaire/' + this.$route.params.questionnaire + '/finish'
                    axios.post(completeUrl, {}).then(() => {
                        localStorage.removeItem('questionnaire')
                        localStorage.removeItem('currentPage')
                        this.finished = true  // Redirect to finish page
                    }).catch(function (error) {
                        console.error(error)
                    })
                // Otherwise, show and error
                } else {
                    this.$notify({
                        group: 'notifications',
                        title: this.$i18n.t('errors.incomplete.title'),
                        text: this.$i18n.t('errors.incomplete.message'),
                        type: 'error'
                    })
                }
            },
            canViewPage: function (page) {            
                
                // If the page doesn't contain any filters, any participant can view it
                if (!page.filters) { return true }

                // If the page contains and filters based on previous question, check the question filters 
                if (page.filters.questions && page.filters.questions.length > 0) { return this.checkQuestionFilters(page.filters.questions) }
                
                // If the page doesn't contain the right configuration for the url and 
                // participant filters the participant can also view the pages
                if (!page.filters.participants && !page.filters.urls) { return true }
                
                // If both the participants and url filters are empty, the user can also view a page
                if (page.filters.participants.length === 0 && page.filters.urls.length === 0) { return true }
                
                // We start checking filters for the participants:
                let participantFilterResult = this.checkParticipantFilters(this.participant.metadata, page.filters.participants || [])
                let urlFilterResult = this.checkUrlFilters(this.url.metadata, page.filters.urls || [])
                
                // If both the participant and url filters pass, we can show the page
                if (participantFilterResult && urlFilterResult) return true
                
                // Otherwise we hide the page
                return false 
            },
            canViewQuestion: function(question) {
                // If the question doesn't have any filters set, any participant van view it
                if (!question.filters) { return true }
                
                // If there are no participants or url filters configured, the participant can also view the question
                if (!question.filters.participants && !question.filters.urls) { return true }

                // If both the participants and url filters are empty, the user can also view a question
                if (question.filters.participants.length === 0 && question.filters.urls.length === 0) { return true }

                // We start checking filters for the participants:
                let participantFilterResult = this.checkParticipantFilters(this.participant.metadata, question.filters.participants || [])
                let urlFilterResult = this.checkUrlFilters(this.url.metadata, question.filters.urls || [])

                // If both the participant and url filters pass, we can show the question
                if (participantFilterResult && urlFilterResult) return true

                // Otherwise we hide the question
                return false 
            },
            checkParticipantFilters: function(participantMetadata, filters) {
                // Check if each filter for the participant is evaluating to true
                return filters.map((filter => {
                    // console.log(participantMetadata[filter["key"]], FILTER_OPERATORS[filter['operator']], filter["value"])

                    // If the filter is "contains" we cannot run an normal eval
                    if (FILTER_OPERATORS[filter['operator']] === 'contains') {
                        return eval('participantMetadata[filter["key"]].toLowerCase()').includes(FILTER_OPERATORS[filter['value'].toLowerCase()])
                    } else {
                        return eval('participantMetadata[filter["key"]] ' + FILTER_OPERATORS[filter['operator']] + ' filter["value"]')
                    }
                })).every((result) => result === true)
            },
            checkUrlFilters: function(urlMetadata, filters) {
                // Check if each filter for the url is evaluating to true
                return filters.map((filter => {
                    // console.log(urlMetadata[filter["key"]], FILTER_OPERATORS[filter['operator']], filter["value"])
                    
                    // If the filter is "contains" we cannot run an normal eval
                    if (FILTER_OPERATORS[filter['operator']] === 'contains') {
                        return eval('urlMetadata[filter["key"]].toLowerCase()').includes(FILTER_OPERATORS[filter['value'].toLowerCase()])
                    } else {
                        return eval('urlMetadata[filter["key"]] ' + FILTER_OPERATORS[filter['operator']] + ' filter["value"]')
                    }
                })).every((result) => result === true)
            },
            checkQuestionFilters: function(questionFilters) {
                return questionFilters.map((questionFilter) => {
                    let answer = this.findAnswerWithQuestionId(questionFilter.key)

                    if (answer) {
                        if (FILTER_OPERATORS[questionFilter['operator']] === 'contains') {
                            return answer.includes(questionFilter.value)
                        } else {
                            return eval('answer ' + FILTER_OPERATORS[questionFilter['operator']] + ' questionFilter["value"]')
                        }
                    } else {
                        return false
                    }
                }).every((result) => result === true)
            },
            findAnswerWithQuestionId: function(questionId) {
                return this.$store.state.answers[questionId]
            }
        },
        mounted() {
            // Based on query parameters, answer questions if possible and navigate to a page
            Object.keys(this.$route.query).map((key) => {
                if (key.startsWith('q')) { // q1=1,q21=3
                    var data = {
                        'question_id': key.substring(1), // Strip q
                        'answer': this.$route.query[key],
                        'questionnaire_id': this.$route.params.questionnaire
                    }
                    // Post data for questions
                    axios.post(process.env.VUE_APP_API_URL +
                        '/api/questionnaire/' + this.$route.params.questionnaire + '/answer', data)
                        .then(() => {
                            this.checkAnswer(data) // Register as input for validity
                        }).catch(function (error) {
                            console.error('error with prefilling question', error)
                        })
                }
                // Navigate to the current page present in the query parameters
                if (key === 'page') {
                    this.currentPage = this.$route.query[key] - 1
                }
            })
        }
    }
</script>
