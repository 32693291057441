<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title has-text-white">
          Nolost Research Environment
        </h1>
        <a :href="admin" class="button is-white is-outlined">Admin Login</a>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'home',
    data: function () {
      return {
        admin: process.env.VUE_APP_API_URL + '/admin'
      }
    }
  }
</script>
