<template>
  <div class="navbar-item has-dropdown" :class="{ 'is-active': dropdown1Active }">
    <div class="navbar-link" @click="toggleDropdown1()">
      <img :src="icons[currentLanguage]" class="country-icon as-toggle"/>
      <span>{{ currentLanguageName }}</span>

      <div class="navbar-dropdown">
        <a v-for="(value, lang) in otherLanguages"
            @click="changeLanguage(lang)"
            class="navbar-item"
            :key="value"
        >
          <img :src="icons[lang]" class="country-icon" />
          <span class="locale-name">{{value}}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import nlIcon from 'svg-country-flags/svg/nl.svg'
  import frIcon from 'svg-country-flags/svg/fr.svg'
  import deIcon from 'svg-country-flags/svg/de.svg'
  import esIcon from 'svg-country-flags/svg/es.svg'
  import enIcon from 'svg-country-flags/svg/gb.svg'

  import _ from 'lodash'
  import { Trans } from '@/plugins/Translation'

  export default {
    data: function() {
      return {
        dropdown1Active: false,
        icons: {
          nl: nlIcon,
          en: enIcon,
          fr: frIcon,
          de: deIcon,
          es: esIcon,
        }
      }
    },
    computed: {
      supportedLanguages () {
        return Trans.supportedLanguagesNames
      },
      currentLanguage () {
        return Trans.currentLanguage
      },
      otherLanguages () {
        return _.omit(Trans.supportedLanguagesNames, [Trans.currentLanguage])
      },
      currentLanguageName () {
        return Trans.currentLanguageName
      }
    },
    methods: {
      changeLanguage (lang) {
        this.$router.resolve({ params: { lang } })
        console.log(localStorage.currentPage)
        return Trans.changeLanguage(lang).then(() => {
          let path = {
            params: {"lang": lang},
          }
          if (this.$router.currentRoute.name === "questionnaire" && !isNaN(parseInt(localStorage.currentPage)) ) {
            path.query = {"page": parseInt(localStorage.currentPage)+1}
          }
          this.$router.push(path)
          if (this.$router.currentRoute.name === "questionnaire") {
            window.location.reload() // Forcefully reload (not elegant, but for custom multiple select there seems to be no other way...)
          }
        })
      },
      isCurrentLanguage (lang) {
        return lang === this.currentLanguage
      },
      toggleDropdown1() {
        this.dropdown1Active = !this.dropdown1Active
      }
    }
  }
</script>


<style>
  .country-icon {
    width: 20px;
    height: auto;
    display: inline-block;
    vertical-align: baseline;
    border: 1px solid #dee2e6;
    box-shadow: 0px 1px 3px rgba(24, 29, 38, 0.1);
    margin-right: 5px;
  }
</style>
