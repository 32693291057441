<template>
  <div class="questionnaire-page">
    <div v-if="page.items.length > 0" class="">
      <div v-for="(item, i) in page.items" :key="i">  

        <!-- Question -->
        <question
          v-if="item.type === 'question' && canViewQuestion(item)"
          :questionObject="item"
          :initialRequired="item.required && canViewQuestion(item)"
          :key="item.id"
          ref="questionsRef"
        />

        <!-- QuestionnaireContent -->
        <questionnaire-content
          v-if="item.type === 'content'"
          :content="item"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Question from './Question.vue'
import QuestionnaireContent from './QuestionnaireContent.vue'
import { FILTER_OPERATORS } from '../../constants/filters.js'

export default {
  name: 'QuestionnairePage',
  props: [
    'page',
    'questions',
    'answers',
    'participant',
    'url'
  ],
  components: {
    Question,
    QuestionnaireContent
  },
  data: function () {
    return {}
  },
  methods : {
    canViewQuestion : function (item) {
      // If the item doesn't contain any filters, any participant can view it
      if (!item.filters) { return true }

      // If the filters don't contain the right configuration for participants & urls
      // The user can also view the question
      if (!item.filters.participants && !item.filters.urls) { return true }

      // If both the participants and url filters are empty, the user can also view a item
      if (item.filters.participants.length === 0 && item.filters.urls.length === 0) { return true }

      // We start checking filters for the participants:
      let participantFilterResult = this.checkParticipantFilters(this.participant.metadata, item.filters.participants || [])
      let urlFilterResult = this.checkUrlFilters(this.url.metadata, item.filters.urls || [])

      // If both the participant and url filters pass, we can show the question
      if (participantFilterResult && urlFilterResult) return true

      // Otherwise we hide the question
      return false 
    },
    checkParticipantFilters(participantMetadata, filters) {
      // Check if each filter for the participant is evaluating to true
      return filters.map((filter => {
        // console.log(participantMetadata[filter["key"]], FILTER_OPERATORS[filter['operator']], filter["value"])
        return eval('participantMetadata[filter["key"]] ' + FILTER_OPERATORS[filter['operator']] + ' filter["value"]')
      })).every((result) => result === true)
    },
    checkUrlFilters(urlMetadata, filters) {
      // Check if each filter for the url is evaluating to true
      return filters.map((filter => {
        // console.log(urlMetadata[filter["key"]], FILTER_OPERATORS[filter['operator']], filter["value"])
        return eval('urlMetadata[filter["key"]] ' + FILTER_OPERATORS[filter['operator']] + ' filter["value"]')
      })).every((result) => result === true)
    },
    addQuestion : function(item) {
      return item
    }
  }
}
</script>

<style>
  .message-body h4{
    margin-bottom: 0px;
  }
</style>

