import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    questionnaire: localStorage.getItem('questionnaire') || false,
    language: localStorage.getItem('language') || 'nl',
    currentPage: localStorage.getItem('currentPage') || 1,
    answers: {},
  },
  mutations: {
    updateAnswer(state, payload) {
      state.answers[payload.question_id] = payload.answer
    },
    initiateAnswers(state, answers) {
      answers.map(function (answer) {
        state.answers[answer.question_id] = answer.value
      })
    }
  },
  actions: {

  },
  getters: {

  }
})
