<template>
  <div class="control">
    <textarea
      id=""
      v-model="answerText"
      :placeholder="placeholder"
      name="answer"
      class="textarea"
      cols="30"
      rows="4"
      @keyup="answer"
      @blur="answernow"
    />
  </div>
</template>

<script>

import _ from 'lodash'

export default {
  name: 'TextareaQuestion',
  props: {
    'questionObject': Object,
    'placeholder': String
  },
  data: function () {
    return {
      answerText: this.getanswerText()
    }
  },
  methods: {
    getanswerText: function () {
      return this.questionObject.answer === undefined ? '' : this.questionObject.answer
    },
    answernow: function () {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerText})
    },
    answer: _.debounce(
      function () {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerText})
      },
      1000,
      {
        'trailing': true,
        'maxWait': 5000
      }
    )
  }
}

</script>
