<template>
  <div class="columns is-mobile">
    <div class="column is-3">
      {{ answerOptions.left.value }}
      <span v-if="answerOptions.left.tooltip" class="has-tooltip-arrow" :data-tooltip="answerOptions.left.tooltip">
        <span class="icon has-text-info is-small pl-3" >
          <i class="fas fa-info-circle"></i>
        </span>
      </span>
    </div>
    <div class="column is-6">
      <vue-slider v-model="value"
                  :tooltip="'none'"
                  :adsorb="true"
                  :min="-5"
                  :max="5"
                  :interval="1"
                  :marks="true"
                  :hide-label="true"
                  :included="true">
      </vue-slider>
    </div>
    <div class="column is-3">
      {{ answerOptions.right.value }}
      <span v-if="answerOptions.right.tooltip" class="has-tooltip-arrow" :data-tooltip="answerOptions.right.tooltip">
        <span class="icon has-text-info is-small pl-3" >
          <i class="fas fa-info-circle"></i>
        </span>
      </span>
    </div>
  </div>
</template>



<script>
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import _ from 'lodash'

export default {
  name: 'CustomChooseScaleQuestion',
  props: [
    'questionObject'
  ],
  data: function () {
    return {
      answerOptions: this.questionObject.metadata.options,
      value: this.getAnswerValue(),
    }
  },
  components: {
    vueSlider
  },
  methods: {
    getAnswerValue: function () {
      return this.questionObject.answer
    }
  },
  watch: {
    value: _.debounce(
      function (value) {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': parseInt(value)})
      }
    , 400) // 500 milliseconds
  }
}

</script>

<style>
  .vue-slider {
    margin: 20px 20px 10px 5px;
  }
  .vue-slider-process, .vue-slider-rail {
    background-color: #E7E9EE;
  }
  .custom-label {
  }
  .custom-label.active {
    color: #0092d0;
    font-weight: bold;
  }
</style>
