<template>
  <table class="table">
    <thead>
      <tr>
        <th></th>
        <th>%</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(value, key) in answerOptions" :key="key">
        <td>
          <label class="" :for="'q'+questionObject.id+'o'+key">
            {{ value.label }}
          </label>
        </td>
        <td>
          <input
              type="number"
              :id="'q'+questionObject.id+'o'+key"
              :value="answers[key]"
              @change="setAnswerValue(key, $event.target.value)"
              min="0"
              max="100"
              step="1"
          > %
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th>Totaal <span class="verdeel-message">{{ message }}</span></th>
        <th>
          <input type="number" :value="sumAnswers" readonly min="0" max="900"> % <br/>
        </th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { NolostResearch } from '@/mixins/NolostResearch'

export default {
  name: 'Custom100PercentTotalQuestion',
  mixins: [NolostResearch],
  props: [
    'questionObject',
    'questiontype'
  ],
  data: function () {
    return {
      answerOptions: this.getAnswerOptions(this.questionObject.metadata.options),
      answerSettings: this.questionObject.metadata.settings,
      answers: this.initialAnswers(),
      sumAnswers: 0,
      message: ""
    }
  },
  methods: {
    initialAnswers: function() {
      let initAns = {}
      if (typeof this.questionObject.answer == "object") {
        initAns = this.questionObject.answer
      }
      else if (this.questionObject.answer) {
        initAns = JSON.parse(this.questionObject.answer.replace(/'/g, '"'))
      }
      return initAns
    },
    getAnswerOptions: function (options) {
      let answerOptions = {}

      for (const prop in options) {
        answerOptions[prop] = {
          id: prop,
          label: options[prop]
        }
      }
      return answerOptions
    },
    setAnswerValue: function (key, value) {
      this.answers[key] = value
      this.calculateSum()
    },
    calculateSum() {
      let sum = 0

      for (const item in this.answers) {
        sum += parseInt(this.answers[item])
      }
      if (sum == 100) {
        this.message = ""
        this.$parent.setSuccess()
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answers})
      }
      else if (sum > 100) {
        this.message = "(" + (sum - 100) + " teveel)"
        this.$parent.setError("Vraag" + this.message)
      }
      else if (sum > 0 && sum < 100) {
        this.message = "(verdeel nog " + (100 - sum) + "%)"
        this.$parent.setError("" + this.message)
      }
      else {
        this.message = "(verdeel nog " + (100 - sum) + "%)"
        this.$parent.unsetError()
      }
      this.sumAnswers = sum
    }
  },
  mounted: function() {
    this.calculateSum()
  }
}

</script>

<style>
.verdeel-message {
  font-weight: normal;
}
</style>
