<template>
  <div class="content" id="questionnaire-page">
    <div class="questionnaire-page">
      <div class="question section questionnaire-content">
        <div class="container">
          <article class="box">
            <h1>{{ content.title }}</h1>
            <div v-html="content.html"></div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'InfoPage',
    data: function () {
      return {
        content: this.getContent(this.$route.params.slug),
      }
    },
    watch: {
      '$i18n.locale' : function() {
        this.getContent(this.$route.params.slug)
      }
    },
    methods: {
      getContent(slug) {
        const url = process.env.VUE_APP_API_URL + '/api/infopage/' + slug
        axios.get(url, {})
          .then((response) => (
            this.content = {
              "title": response.data.title,
              "html": response.data.html
            }
          ))
          .catch(() => {
            this.content = {
              "title": 'Page not found',
              "html": 'Page ' + slug + ' was not found, please check url.'
            }
          })
      }
    }
  }
</script>
