<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="content" v-if="questionnaire">
          <h1 class="title">{{ $t('interface.bestaandevragenlijst') | capitalize }}</h1>
          <p>{{ $t('interface.bestaand-message') | capitalize }}</p>
          <div class="buttons">
            <button class="button is-success is-medium" @click="gotoQuestionnaire">
              <span class="icon">
                <i class="far fa-file-alt"></i>
              </span>
              <span>{{ $t('interface.bestaand-yes') | capitalize }}</span>
            </button>

            <button class="button is-warning is-medium" @click="createUser">
              <span class="icon">
                <i class="far fa-file"></i>
              </span>
              <span>{{ $t('interface.bestaand-no') | capitalize }}</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'PublicStart',
    data: function () {
      return {
        questionnaire: false,
        slug: this.$route.params.slug,
        language: this.$i18n.locale,
        currentPage: 1,
      }
    },
    mounted() {
      if (localStorage.questionnaire && localStorage.slug == this.slug) {
        this.questionnaire = localStorage.questionnaire
        if (!isNaN(parseInt(localStorage.currentPage))) {
          this.currentPage = parseInt(localStorage.currentPage)+1
        }
      }
      this.checkUser()
    },
    methods: {
      checkUser () {
        // check if user was created and questionnaire not finished
        // Only create user if no questionnaire was found
        if (!this.questionnaire) {
          this.createUser()
        }
      },
      gotoQuestionnaire () {
        localStorage.questionnaire = this.questionnaire
        localStorage.slug = this.slug
        localStorage.currentPage = this.currentPage

        this.$router.push({
          path: '/' + this.language + '/questionnaire/' + this.questionnaire,
          query: { page:  this.currentPage}
        })
      },
      createUser () {
        const url = process.env.VUE_APP_API_URL + '/api/questionnaire/' + this.language + '/' + this.$route.params.slug + '/create'
        axios.get(url)
        .then((response) => (
          // New login created, set localstorage and continue to questionnaire
          this.language = response.data.language,
          this.questionnaire = response.data.questionnaire,
          this.currentPage = 1,
          this.gotoQuestionnaire()
        ))
        .catch((response) => {
          this.$notify({
            group: 'notifications',
            title: this.$i18n.t('errors.start.title'),
            text: response.data,
            //text: this.$i18n.t('errors.start.message'),
            type: 'error'
          })
        })
      },
    },
    watch: {
      '$i18n.locale' : function() {
        this.createUser()
      }
    },
  }
</script>
