import Vue from 'vue'
import Notifications from 'vue-notification'
import VueI18n from 'vue-i18n'
import App from './App'
import { i18n } from './plugins/i18n'
import router from './router'
import store from '@/plugins/store'
import * as Sentry from '@sentry/browser'
import '@/assets/styles.scss'

Vue.use(Notifications)
Vue.use(VueI18n)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue: Vue,
    dsn: 'https://5f3f20882ec9433fbf46b3e2d1978746@sentry.io/1413763',
  })
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  components: { App },
  render: (h) => h(App)
}).$mount('#app')

