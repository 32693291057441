<template>
  <div class="columns radios is-multiline">
    <div class="column" v-for="(value, key) in answerOptions" :class="getColumnSize(Object.keys(answerOptions).length, answerSettings)" :key="key">
      <div class="field">
        <input :key="questionObject.id"
               :name="questionObject.id"
               v-model="answerValue"
               :value="key"
               type="radio"
               class="is-checkradio"
               :id="'q'+questionObject.id+'o'+key"
        >
        <label class="" :for="'q'+questionObject.id+'o'+key">
          {{ value }}
        </label>
      </div>
    </div>

    <div class="column is-full" v-if="answerSettings && answerSettings.hasOwnProperty('other')">
      <div class="field is-grouped">
        <p class="control">
          <input :key="questionObject.id"
                 name="checkbox_setting_other"
                 v-model="answerValue"
                 value="setting_other"
                 type="radio"
                 class="is-checkradio"
                 :id="'q'+questionObject.id+'o_setting_other'"
          >
          <label class="" :for="'q'+questionObject.id+'o_setting_other'">
            {{ answerSettings.other }}
          </label>
        </p>
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            name="checkbox_setting_other_value"
            v-model="otherValue"
            v-on:keyup="answer"
            v-on:blur="answernow"
            :id="'q'+questionObject.id+'o_setting_other_value'"
            v-if="getAnswerValue().includes('setting_other')"
          >
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { NolostResearch } from '@/mixins/NolostResearch'
import _ from 'lodash'

export default {
  name: 'CustomSingleSelectQuestion',
  mixins: [NolostResearch],
  props: [
    'questionObject',
    'questiontype'
  ],
  data: function () {
    return {
      answerOptions: this.questionObject.metadata.options,
      answerSettings: this.questionObject.metadata.settings,
      answerValue: this.getAnswerValue(),
      otherValue: this.getOtherValue(),
      settingOtherValue: ''
    }
  },
  watch: {
    answerValue: function (value) {
      if (value.includes('setting_other')) {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': value, 'other_value': this.otherValue})
      }
      else {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': value})
      }
    }
  },
  methods: {
    getAnswerValue: function () {
      // Return value of property on first load, then the value of the answervalue v-model
      if (this.answerValue === undefined) {
        if (this.questionObject.answer === undefined) {
          return ''
        }
        return this.questionObject.answer
      }
      return this.answerValue
    },
    getOtherValue: function () {
      // Return value of property on first load, then the value of the othervalue v-model
      if (this.otherValue === undefined) {
        return this.questionObject.answer_other_value
      }
      return this.otherValue
    },
    answernow: function () {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerValue, 'other_value': this.otherValue})
    },
    answer: _.debounce(
      function () {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerValue, 'other_value': this.otherValue})
      },
      1000,
      {
        'trailing': true,
        'maxWait': 5000
      }
    )
  }
}

</script>

<style>

</style>
