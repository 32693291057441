<template>
  <div class="columns radios is-centered is-multiline">
    <div class="column" v-for="(label, i) in labels" :class="getColumnSize(labels.length)" :key="i">
      <div class="field">
        <input :key="questionObject.id" :name="questionObject.id" v-model="answerValue" :value="i+1" type="radio" class="is-checkradio" :id="'q'+questionObject.id+'o'+i">
        <label class="" :for="'q'+questionObject.id+'o'+i">
          {{ $t(label) | capitalize }}
        </label>
      </div>
    </div>
  </div>
</template>


<script>
import { NolostResearch } from '@/mixins/NolostResearch'

export default {
  name: 'LikertQuestion',
  mixins: [NolostResearch],
  props: [
    'questionObject',
    'questiontype'
  ],
  data: function () {
    return {
      answerValue: this.getAnswerValue(),
      questionId: this.questionObject.id,
      labels: this.getLabels(this.questiontype)
    }
  },
  methods: {
    getAnswerValue: function () {
      return this.questionObject.answer
    },
    getLabels: function (qtype) {
      let answers = ['ERROR: questionObject.qtype unknown']
      switch (qtype) {
        case '5-point-likert-agree':
        case '51-point-likert-agree':
          answers = ['antwoorden.likert.agree1', 'antwoorden.likert.agree2', 'antwoorden.likert.agree4', 'antwoorden.likert.agree6', 'antwoorden.likert.agree7']
          break

        case '7-point-likert-agree':
        case '71-point-likert-agree':
          answers = ['antwoorden.likert.agree1', 'antwoorden.likert.agree2', 'antwoorden.likert.agree3', 'antwoorden.likert.agree4', 'antwoorden.likert.agree5', 'antwoorden.likert.agree6', 'antwoorden.likert.agree7']
          break

        case '5-point-likert-fast-slow':
        case '51-point-likert-fast-slow':
          answers = ['antwoorden.likert.fast1', 'antwoorden.likert.fast2', 'antwoorden.likert.fast4', 'antwoorden.likert.fast6', 'antwoorden.likert.fast7']
          break

        case '7-point-likert-fast-slow':
        case '71-point-likert-fast-slow':
          answers = ['antwoorden.likert.fast1', 'antwoorden.likert.fast2', 'antwoorden.likert.fast3', 'antwoorden.likert.fast4', 'antwoorden.likert.fast5', 'antwoorden.likert.fast6', 'antwoorden.likert.fast7']
          break

        case '5-point-likert-frequency':
        case '51-point-likert-frequency':
          answers = ['antwoorden.likert.freq1', 'antwoorden.likert.freq2', 'antwoorden.likert.freq4', 'antwoorden.likert.freq6', 'antwoorden.likert.freq7']
          break

        case '5-point-likert-occurance':
        case '51-point-likert-occurance':
          answers = ['antwoorden.likert.occ1', 'antwoorden.likert.occ2', 'antwoorden.likert.occ4', 'antwoorden.likert.occ6', 'antwoorden.likert.occ7']
          break

        case '7-point-likert-frequency':
        case '71-point-likert-frequency':
          answers = ['antwoorden.likert.freq1', 'antwoorden.likert.freq2', 'antwoorden.likert.freq3', 'antwoorden.likert.freq4', 'antwoorden.likert.freq5', 'antwoorden.likert.freq6', 'antwoorden.likert.freq7']
          break

        case '7-point-likert-occurance':
        case '71-point-likert-occurance':
          answers = ['antwoorden.likert.occ1', 'antwoorden.likert.occ2', 'antwoorden.likert.occ3', 'antwoorden.likert.occ4', 'antwoorden.likert.occ5', 'antwoorden.likert.occ6', 'antwoorden.likert.occ7']
          break

        case '5-point-likert-good-bad':
        case '51-point-likert-good-bad':
          answers = ['antwoorden.likert.good1', 'antwoorden.likert.good2', 'antwoorden.likert.good4', 'antwoorden.likert.good6', 'antwoorden.likert.good7']
          break

        case '7-point-likert-good-bad':
        case '71-point-likert-good-bad':
          answers = ['antwoorden.likert.good1', 'antwoorden.likert.good2', 'antwoorden.likert.good3', 'antwoorden.likert.good4', 'antwoorden.likert.good5', 'antwoorden.likert.good6', 'antwoorden.likert.good7']
          break

        case '5-point-likert-high-low':
        case '51-point-likert-high-low':
          answers = ['antwoorden.likert.high1', 'antwoorden.likert.high2', 'antwoorden.likert.high4', 'antwoorden.likert.high6', 'antwoorden.likert.high7']
          break

        case '7-point-likert-high-low':
        case '71-point-likert-high-low':
          answers = ['antwoorden.likert.high1', 'antwoorden.likert.high2', 'antwoorden.likert.high3', 'antwoorden.likert.high4', 'antwoorden.likert.high5', 'antwoorden.likert.high6', 'antwoorden.likert.high7']
          break

        case '5-point-likert-important':
        case '51-point-likert-important':
          answers = ['antwoorden.likert.important1', 'antwoorden.likert.important2', 'antwoorden.likert.important4', 'antwoorden.likert.important6', 'antwoorden.likert.important7']
          break

        case '7-point-likert-important':
        case '71-point-likert-important':
          answers = ['antwoorden.likert.important1', 'antwoorden.likert.important2', 'antwoorden.likert.important3', 'antwoorden.likert.important4', 'antwoorden.likert.important5', 'antwoorden.likert.important6', 'antwoorden.likert.important7']
          break

        case '5-point-likert-long-short':
        case '51-point-likert-long-short':
          answers = ['antwoorden.likert.long1', 'antwoorden.likert.long2', 'antwoorden.likert.long4', 'antwoorden.likert.long6', 'antwoorden.likert.long7']
          break

        case '7-point-likert-long-short':
        case '71-point-likert-long-short':
          answers = ['antwoorden.likert.long1', 'antwoorden.likert.long2', 'antwoorden.likert.long3', 'antwoorden.likert.long4', 'antwoorden.likert.long5', 'antwoorden.likert.long6', 'antwoorden.likert.long7']
          break
      }

      if (qtype.indexOf('1-p') > 0) { // a .1 likert variant, add extra option
        answers.push('antwoorden.likert.nvt')
      }
      return answers
    }
  },
  watch: {
    answerValue: function (value) {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': parseInt(value)})
    }
  }
}

</script>

<style>

</style>
