<template>
  <div>
    <div class="columns radios">
      <div class="column is-1" v-for="n in 10" :key="n">
        <div class="field">
          <input :key="questionObject.id" :name="questionObject.id" v-model="answerValue" :value="n" type="radio" :id="'q'+questionObject.id+'o'+n" class="is-checkradio">
          <label class="" :for="'q'+questionObject.id+'o'+n">
            {{n}}
            <span v-if="n===1" class="extra-label-nps is-hidden-tablet">{{ $t('antwoorden.nps.onwaarschijnlijk') | capitalize }}</span>
            <span v-if="n===10" class="extra-label-nps is-hidden-tablet">{{ $t('antwoorden.nps.waarschijnlijk') | capitalize }}</span>
          </label>
        </div>
      </div>
    </div>
    <div class="columns is-hidden-mobile">
      <div class="column is-5 has-text-left">{{ $t('antwoorden.nps.onwaarschijnlijk') | capitalize }}</div>
      <div class="column is-5 has-text-right">{{ $t('antwoorden.nps.waarschijnlijk') | capitalize }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NpsQuestion',
  props: [
    'questionObject',
    'questiontype'
  ],
  data: function () {
    return {
      answerValue: this.getAnswerValue()
    }
  },
  methods: {
    getAnswerValue: function () {
      return this.questionObject.answer
    }
  },
  watch: {
    answerValue: function (value) {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': parseInt(value)})
    }
  }
}

</script>

<style>

</style>
