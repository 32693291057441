<template>
    <div class="columns radios yesno">
      <div class="column">
        <div class="field">
          <input :name="questionObject.id" v-model="answerValue" value="yes" type="radio" class="is-checkradio" :id="'q'+questionObject.id+'_yes'">
          <label class="" :for="'q'+questionObject.id+'_yes'">
            {{ $t('antwoorden.yesno.yes') | capitalize }}
          </label>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <input :name="questionObject.id" v-model="answerValue" value="no" type="radio" class="is-checkradio" :id="'q'+questionObject.id+'_no'">
          <label class="" :for="'q'+questionObject.id+'_no'">
            {{ $t('antwoorden.yesno.no') | capitalize }}
          </label>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'YesnoQuestion',
  props: [
    'questionObject'
  ],
  data: function () {
    return {
      answerValue: this.getAnswerValue()
    }
  },
  methods: {
    getAnswerValue: function () {
      return this.questionObject.answer
    }
  },
  watch: {
    answerValue: function (value) {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': value})
    }
  }
}

</script>
