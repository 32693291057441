<template>
  <div class="columns radios is-multiline" style="padding: 14px;">
    <div 
      v-for="(value, key) in answerOptions"
      class="column"
      style="padding: 0px" 
      :class="getColumnSize(Object.keys(answerOptions).length, answerSettings)" 
      :key="key + '_' + currentLanguage">
      <div class="field">
        <input :key="questionObject.id + currentLanguage"
               :name="'checkbox_' + key + currentLanguage"
               :value="key"
               :id="'q'+questionObject.id + 'o' + key + currentLanguage"
               v-model="answerValue"
               type="checkbox"
               class="is-checkradio"
        >
        <label 
          style="display:inline-block; padding-top: 0px"
          class=""
          :for="'q' + questionObject.id + 'o' + key + currentLanguage" 
          :key="'q_' + questionObject.id + '_' + key + '_' + currentLanguage">
          {{ value }}
        </label>
      </div>
    </div>

    <div class="column is-full" style="padding: 0px"  v-if="answerSettings && answerSettings.hasOwnProperty('other')">
      <div class="field">
        <input :key="questionObject.id"
                name="checkbox_setting_other"
                v-model="answerValue"
                value="setting_other"
                type="checkbox"
                class="is-checkradio"
                :id="'q' + questionObject.id + 'o_setting_other'">
        <label style="display:inline-block; padding-top: 0px" class="" :for="'q' + questionObject.id + 'o_setting_other'">
          {{ answerSettings.other }}
        </label>
        <input
          class="input"
          type="text"
          name="checkbox_setting_other_value"
          v-model="otherValue"
          v-on:keyup="answer"
          v-on:blur="answernow"
          :id="'q' + questionObject.id + 'o_setting_other_value'"
          v-if="answerValue.includes('setting_other')"
        >
      </div>
    </div>

  </div>
</template>

<script>
import { NolostResearch } from '@/mixins/NolostResearch'
import { Trans } from '@/plugins/Translation'
import _ from 'lodash'

export default {
  name: 'CustomMultipleSelectQuestion',
  mixins: [NolostResearch],
  props: [
    'questionObject',
    'questiontype'
  ],
  data: function () {
    return {
      answerOptions: this.questionObject.metadata.options,
      answerSettings: this.questionObject.metadata.settings,
      answerValue: this.getAnswerValue(),
      otherValue: this.getOtherValue(),
      settingOtherValue: ''
    }
  },
  computed: {
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  watch: {
    answerValue: function (value) {
      if (value.includes('setting_other')) {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': value, 'other_value': this.otherValue})
      }
      else {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': value})
      }
    }
  },
  methods: {
    getAnswerValue: function () {
      // If it is an array, return the array
      if (Array.isArray(this.questionObject.answer)) {
        return this.questionObject.answer
      }
      // If not, maybe we can parse the string as JSON
      try {
        return JSON.parse(this.questionObject.answer.replace(/'/g, '"'))
      }
      catch (e) {
        // If everything fails, just return the empty array
        return []
      }
    },
    getOtherValue: function () {
      // Return value of property on first load, then the value of the othervalue v-model
      if (this.otherValue == undefined) {
        return this.questionObject.answer_other_value
      }
      return this.otherValue
    },
    answernow: function () {
      this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerValue, 'other_value': this.otherValue})
    },
    answer: _.debounce(
      function () {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerValue, 'other_value': this.otherValue})
      },
      1000,
      {
        'trailing': true,
        'maxWait': 5000
      }
    )
  }
}

</script>

<style>

</style>
