// Used for parsing filters for the pages and questions
export const FILTER_OPERATORS = {
    // Equality operators
    '=': '==',
    '==': '==',
    '===': '==',
    'equal': '==',
    'equals': '==',

    // Less or/is more
    '>': '>',
    'more': '>',
    '<': '<',
    'less': '<',
    '<=': '<=',
    'less_or_equal': '<=',
    '>=': '>=',
    'more_or_equal': '>=',
    
    // Contains? (should branch of and provide some different logic)
    'contains': 'contains',
    'contain': 'contains',
    '?': 'contains',
}