<template>
  <div class="columns">
    <div class="column">
      <vue-slider v-model="value"
                  :tooltip="'always'"
                  :adsorb="true"
                  :min="0"
                  :max="10"
                  :interval="0.5"
                  :marks="marks"
                  :included="true">
        <div class="sliderLabel" slot="label" slot-scope="{label, active}">
          <span :class="['custom-label', { active }]" v-if="label % 1 === 0">{{ label }}</span>
        </div>
      </vue-slider>
    </div>
  </div>
</template>


<script>
import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import _ from 'lodash'

export default {
  name: 'GradeQuestion',
  props: [
    'questionObject'
  ],
  data: function () {
    return {
      value: this.getAnswerValue(),
      marks: ["-", 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]
    }
  },
  components: {
    vueSlider
  },
  methods: {
    getAnswerValue: function () {
      return this.questionObject.answer
    }
  },
  watch: {
    value: _.debounce(
      function (value) {
        if (value == "-") {
          this.$emit('answer', {'question_id': this.questionObject.id, 'answer': ''})
        } else {
          this.$emit('answer', {'question_id': this.questionObject.id, 'answer': parseFloat(value)})
        }
      }
    , 400) // 500 milliseconds
  }
}

</script>

<style>
  .vue-slider {
    margin: 20px 20px 10px 5px;
  }
  .custom-label {
  }
  .custom-label.active {
    color: #0092d0;
    font-weight: bold;
  }
</style>
