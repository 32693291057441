<template>

  <div class="question section" :class="status" :id="questionObject.id">
    <div class="container">
      <article class="box">
          <p class="is-size-4 has-text-primary has-text-weight-semibold">
            {{ questionObject.text }}
            <span v-if="questionObject.tooltip" class="has-tooltip-arrow" :data-tooltip="questionObject.tooltip">
              <span class="icon has-text-info is-small pl-3" >
                <i class="fas fa-info-circle"></i>
              </span>
            </span>
            <span v-if="loading" class="loader is-size-6" style="display:inline-block"></span>
          </p>
          <p v-if="questionObject.description" v-html="questionObject.description"></p>

          <likert-question
            v-if="questionObject.qtype.indexOf('likert') > 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </likert-question>

          <textfield-question
            v-if="questionObject.qtype === 'open-question-one-line'"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            placeholder="placeholder">
          </textfield-question>

          <textarea-question
            v-if="questionObject.qtype === 'open-question'"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            placeholder="">
          </textarea-question>

          <yesno-question
            v-if="questionObject.qtype === 'yes-no'"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion">
          </yesno-question>

          <nps-question
            v-if="questionObject.qtype.indexOf('nps') == 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </nps-question>

          <custom-multiple-select-question
            v-if="questionObject.qtype.indexOf('custom-multiple-select') == 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </custom-multiple-select-question>

          <custom-single-select-question
            v-if="questionObject.qtype.indexOf('custom-single-select') == 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </custom-single-select-question>

          <custom-choose-scale-question
            v-if="questionObject.qtype.indexOf('custom-choose-scale') == 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </custom-choose-scale-question>

          <custom-100-percent-total-question
            v-if="questionObject.qtype.indexOf('custom-100-percent-total') == 0"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion"
            :questiontype = questionObject.qtype
          >
          </custom-100-percent-total-question>

          <grade-question
            v-if="questionObject.qtype === 'grade-1-10-open'"
            v-bind:questionObject="questionObject"
            v-on:answer="answerQuestion">
          </grade-question>

          <div v-if="status == 'question-error'" class="notification is-danger">
            {{ errorMessage }}
          </div>
    </article>
    </div>
  </div>
</template>


<script>

  import axios from 'axios'
  import LikertQuestion from './question_types/LikertQuestion.vue'
  import TextfieldQuestion from './question_types/TextfieldQuestion.vue'
  import TextareaQuestion from './question_types/TextareaQuestion.vue'
  import YesnoQuestion from './question_types/YesnoQuestion.vue'
  import NpsQuestion from './question_types/NpsQuestion.vue'
  import GradeQuestion from './question_types/GradeQuestion.vue'
  import CustomMultipleSelectQuestion from "./question_types/CustomMultipleSelectQuestion";
  import Custom100PercentTotalQuestion from "./question_types/Custom100PercentTotalQuestion";
  import CustomChooseScaleQuestion from "./question_types/CustomChooseScaleQuestion";
  import CustomSingleSelectQuestion from "./question_types/CustomSingleSelectQuestion";

  export default {
    name: 'Question',
    props: {
      questionObject: Object,
      initialRequired: Boolean,
      initialCompleted : Boolean
    },
    data: function () {
      return {
        status: '',
        errorMessage : '',
        isRequired: true,
        isCompleted: false,
        answerValue: '',
        loading: false
      }
    },
    created: function () {
      this.isRequired = this.initialRequired
      if (this.isRequired) {
        this.status = 'question-required';
      }
      this.isCompleted = this.initialCompleted
      if (this.questionObject.answer) {
        this.setSuccess()
      }
    },
    components: {
      CustomMultipleSelectQuestion,
      CustomSingleSelectQuestion,
      Custom100PercentTotalQuestion,
      CustomChooseScaleQuestion,
      LikertQuestion,
      TextfieldQuestion,
      TextareaQuestion,
      YesnoQuestion,
      NpsQuestion,
      GradeQuestion
    },
    methods: {
      answerQuestion: function (payload) {
        this.loading = true
        this.validateAnswer(payload.answer, payload.other_value)
        
        if (this.errorMessage === undefined || this.errorMessage === "") {

          payload.participant_id = this.$parent.participant.id
          payload.question_type = this.questionObject.qtype

          this.questionObject.answer = payload.answer // eslint-disable-line vue/no-mutating-props
          axios.post(process.env.VUE_APP_API_URL +
          '/api/questionnaire/' + this.$route.params.questionnaire + '/answer', payload)
          .then(() => {
              this.$store.commit('updateAnswer', payload)
              this.setSuccess()
            })
            .catch(() => (this.setError(this.$i18n.t('errors.rest_service_unavailable.title') + ": " + this.$i18n.t('errors.rest_service_unavailable.message'))))
        } else {
          this.setError(this.errorMessage)
        }
      },
      setSuccess: function () {
        this.loading = false
        this.status = 'question-success'
        this.errorMessage = ''
        this.isCompleted = true
      },
      setError: function (msg = '') {
        this.loading = false
        this.status = 'question-error'
        this.errorMessage = msg
      },
      unsetError: function () {
        this.status = ''
        this.errorMessage = ''
      },
      validateAnswer: function (answer, other_value) {
        this.errorMessage = ''

        // If it's possible to add a open answer (ie custom multie select with 'other answer')
        // then that needs to be filled in
        if (Array.isArray(answer) && answer.includes("setting_other") && (other_value === "" || other_value === undefined)) {
          this.errorMessage = this.$i18n.t('errors.incomplete_question.title')
        }

        // Parse and execute question specific validation rules from json if set
        if (this.questionObject.validation_rules) {
          try {
            const rules = JSON.parse(this.questionObject.validation_rules)
            // Check max items if part of rules
            if (Object.prototype.hasOwnProperty.call(rules,'max')) {
              // Remove "other_value=" from array
              answer.forEach(function (item, index) {
                if (item.startsWith("other_value=")) {
                  answer.splice(index, 1);
                }
              })

              if (answer.length > rules.max) {
                // Show error notification
                this.$notify({
                  group: 'notifications',
                  title: this.$i18n.tc('errors.max_violation.title', rules.max),
                  text: this.$i18n.tc('errors.max_violation.message', rules.max, { vraag: this.questionObject.text }),
                  type: 'error'
                })

                // Set error message to prevent store answer
                this.errorMessage = this.$i18n.tc('errors.max_violation.title', rules.max)
              }
            }
            // Check min items if part of rules
            if (Object.prototype.hasOwnProperty.call(rules, 'min')) {
              if (answer.length < rules.min) {
                this.setError(this.$i18n.tc('errors.min_violation.title', rules.min))
              }
            }
          }
          catch (e) {
            // continue regardless of error
            console.error(e);
          }
        }

        // Default checks for required field
        if (this.isRequired) {
          if (typeof answer === 'undefined') {
            this.errorMessage = this.$i18n.t('errors.incomplete_question.title')
          }
          else if (typeof answer === 'string' && answer === "") {
            this.errorMessage = this.$i18n.t('errors.incomplete_question.title')
          }
          else if (Array.isArray(answer) && answer.length === 0) {
            this.errorMessage = this.$i18n.t('errors.incomplete_question.title')
          }
          else if (typeof answer === 'number' && answer == 0) {
            this.errorMessage = this.$i18n.t('errors.incomplete_question.title')
          }
        }
      }
    }
  }

</script>


<style>
  .question{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .question .box{
    border-right: 10px #fff solid;
  }
  .question-success .box{
    border-right: 10px #00ac85 solid;
  }
  .question-required .box{
    border-right: 10px hsl(48, 100%, 67%) solid;
  }
  .question-error .box{
    border-right: 10px #e4003a solid;
  }
</style>
