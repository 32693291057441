<template>
  <div>
    <input 
      v-on:keyup="answer" 
      v-on:blur="answernow"
      v-model="answerText"
      type="text" 
      class="input"
      placeholder="..."/>
  </div>
</template>


<script>
  import _ from 'lodash'

  export default {
    name: 'TextfieldQuestion',
    props: [
      'questionObject'
    ],
    data: function () {
      return {
        answerText: this.getAnswerText()
      }
    },
    methods: {
      getAnswerText: function () {
        return this.questionObject.answer === undefined ? '' : this.questionObject.answer
      },
      answernow: function () {
        this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerText})
      },
      answer: _.debounce(
        function () {
          this.$emit('answer', {'question_id': this.questionObject.id, 'answer': this.answerText})
        },
        1000,
        {
          'trailing': true,
          'maxWait': 5000
        }
      )
    }
  }

</script>
