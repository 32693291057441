<template>
  <router-view />
</template>


<script>
  export default {
    name: 'LocalPage'
  }
</script>
